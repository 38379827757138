import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import NotFound from "../Components/Common/NotFound";
import PublicRoutes from "./PublicRoutes";
import PrivateAuth from "./PrivateAuth";
import PrivateRoutes from "./PrivateRoutes";
import PublicAuth from "./PublicAuth";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/app" element={<PrivateAuth />}>
        {PrivateRoutes()}
      </Route>
      <Route path="/" element={<PublicAuth />}>
        {PublicRoutes()}
      </Route>
      <Route path="*" element={<NotFound />} />
    </>
  )
);

export default router;

// const AppRoutes = () => (
//   <Routes>
//     <Route path={"/app"} element={<PrivateAuth />}>
//       {PrivateRoutes()}
//     </Route>
//     <Route path={"/"} element={<PublicAuth />}>
//       {PublicRoutes()}
//     </Route>
//     <Route path={"*"} element={<NotFound />} />
//   </Routes>
// );
//
// export default memo(AppRoutes);
