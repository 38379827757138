import { Modal, Button } from "react-bootstrap";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { EMAIL_DOMAIN, EMAIL_VERIFICATION } from "Constants";
import { useNavigate } from "react-router-dom";
import { resendEmailVerification } from "Redux/Auth/RegisterReducer";
import { appendEmailDomain } from "../../Utils";
import { useDispatch } from "react-redux";
import { ForgotPasswordAction } from "../../Redux/Auth/ForgotPasswordReducer";
import PropTypes from "prop-types";

const VerificationModal = ({ onHide, show, email, type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/");
    onHide();
  };

  const resendEmail = (e) => {
    e.preventDefault();
    const payload = appendEmailDomain({ email });
    if (type === EMAIL_VERIFICATION) {
      dispatch(resendEmailVerification(payload));
    } else {
      dispatch(ForgotPasswordAction(payload));
    }
  };

  return (
    <>
      <div className="badges-modal-wrapper">
        <Modal
          onHide={onHide}
          show={show}
          size="lg"
          className="verification-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="text-center" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {type === EMAIL_VERIFICATION
                ? t("VerificationModal.Title")
                : t("ForgotPassword.Title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="divider-dual"></div>
            <div className="modal-desc">
              {type === EMAIL_VERIFICATION
                ? t("VerificationModal.EmailHeader")
                : t("VerificationModal.PasswordHeader")}{" "}
              {email}@{EMAIL_DOMAIN}
            </div>
            <Button className="modal-btn" onClick={handleClick}>
              {t("Login.Title")}
            </Button>
            <div className="request-link" onClick={resendEmail}>
              {t("VerificationModal.Link")}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

VerificationModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default VerificationModal;
