import FooterLogo from "Assets/Images/Home/logo.svg";
import "./style.scss";

const Footer = () => {
  return (
    <div className="bottom-footer text-end">
      <img src={FooterLogo} alt="" />
    </div>
  );
};

export default Footer;
