import "./App.scss";
import "Assets/Scss/style.scss";
import "Assets/Scss/variables.scss";
import { RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader } from "Components/Common";
import router from "Routes";
import { RotatePhone } from "Components";

function App() {
  const commonActions = useSelector((state) => state.CommonReducer);

  return (
    <>
      <div className={"rotate"}>
        <RotatePhone />
      </div>
      <div className={"pages"}>
        {commonActions.isLoading && <Loader />}
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
