import apiCall from "Middlewares/ApiClient";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCallTypes } from "Constants";
import { SnackBar } from "Components/Common";

const initialState = {
  isLoading: false,
  roles: [],
};

export const registerUser = createAsyncThunk(
  "Register/RegisterAction",
  async (payload, thunkApi) => {
    const res = await apiCall(
      "/auth/email/registration",
      apiCallTypes.post,
      payload,
      false
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const getRoles = createAsyncThunk(
  "Roles/RolesAction",
  async (payload, thunkApi) => {
    const res = await apiCall("/roles/list", apiCallTypes.get);

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const resendEmailVerification = createAsyncThunk(
  "Register/ResendEmail",
  async (payload, thunkApi) => {
    const res = await apiCall(
      "/auth/verify/resend/email",
      apiCallTypes.post,
      payload,
      false
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const RegisterSlice = createSlice({
  name: "RegisterReducer",
  initialState,
  extraReducers: {
    [registerUser.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [registerUser.rejected]: (state) => {
      state.isLoading = false;
    },
    [registerUser.pending]: (state) => {
      state.isLoading = true;
    },
    [getRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload.data ? [...payload.data.data] : [];
      state.isLoading = false;
    },
    [getRoles.rejected]: (state) => {
      state.isLoading = false;
    },
    [getRoles.pending]: (state) => {
      state.isLoading = true;
    },
    [resendEmailVerification.fulfilled]: (state, { payload }) => {
      SnackBar("success", payload.data.message);
      state.isLoading = false;
    },
    [resendEmailVerification.rejected]: (state) => {
      state.isLoading = false;
    },
    [resendEmailVerification.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default RegisterSlice.reducer;
