import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const CommonSlice = createSlice({
  name: "CommonReducer",
  initialState,
  reducers: {
    setLoader: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});
export const { setLoader } = CommonSlice.actions;
export default CommonSlice.reducer;
