import { Modal, ProgressBar } from "react-bootstrap";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPercentage } from "Utils";

const BadgesModal = (props) => {
  const { t } = useTranslation();
  const { home } = useSelector((state) => state.ProfileReducer);

  return (
    <div className="badges-modal-wrapper">
      <Modal
        {...props}
        size="lg"
        className="badges-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Badges.Title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-badge">
            <div className="badge-name">
              {home?.profile?.current_badge?.name}
            </div>
            <img src={home?.profile?.current_badge?.icon?.url} alt="badge" />
            <div className="current-score">
              <ProgressBar
                now={
                  !home?.profile?.current_badge?.ending_score
                    ? 100
                    : getPercentage(
                        home?.profile?.total_score,
                        home?.profile?.current_badge?.ending_score
                      )
                }
              />
              <div className="score">
                <span>{home?.profile?.total_score}</span>
                {!!home?.profile?.current_badge?.ending_score && (
                  <span>/{home?.profile?.current_badge?.ending_score}</span>
                )}
              </div>
            </div>
          </div>
          <div className="divider-dual" />
          <div className="badges-grid">
            {home?.all_badges?.map((badge) => (
              <div className="badge-item active" key={badge?.id}>
                <img src={badge?.icon?.url} alt="badge" />
                <div className="semibold">{badge?.name}</div>
                <div className="light">
                  {badge?.ending_score
                    ? badge?.starting_score + "-" + badge?.ending_score
                    : badge?.starting_score + " <"}
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BadgesModal;
