import apiCall from "Middlewares/ApiClient";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCallTypes } from "Constants";
import { SnackBar } from "Components/Common";
import { getCompletedBadges } from "../Utils";

const initialState = {
  isLoading: false,
  user: {},
  home: {},
};

export const ProfileAction = createAsyncThunk(
  "Profile/ProfileAction",
  async (payload, thunkApi) => {
    const res = await apiCall("/auth/me", apiCallTypes.get);

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const ChangePasswordAction = createAsyncThunk(
  "ChangePassword/ChangePasswordAction",
  async (payload, thunkApi) => {
    const res = await apiCall(
      "/auth/change/password",
      apiCallTypes.post,
      payload
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const HomeAction = createAsyncThunk(
  "Profile/HomeAction",
  async (payload, thunkApi) => {
    const res = await apiCall("/auth/home", apiCallTypes.get);

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const ProfileSlice = createSlice({
  name: "ProfileReducer",
  initialState,
  extraReducers: {
    [ProfileAction.fulfilled]: (state, { payload }) => {
      state.user = payload?.data?.data;
      state.isLoading = false;
    },
    [ProfileAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [ProfileAction.pending]: (state) => {
      state.isLoading = true;
    },
    [ChangePasswordAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      SnackBar("success", payload?.data?.message);
    },
    [ChangePasswordAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [ChangePasswordAction.pending]: (state) => {
      state.isLoading = true;
    },
    [HomeAction.fulfilled]: (state, { payload }) => {
      state.home = {
        ...payload?.data?.data,
        all_badges: getCompletedBadges(
          payload?.data?.data?.all_badges,
          payload?.data?.data?.profile?.total_score
        ),
      };
      state.isLoading = false;
    },
    [HomeAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [HomeAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default ProfileSlice.reducer;
