import apiCall from "Middlewares/ApiClient";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCallTypes } from "Constants";
import { SnackBar } from "Components/Common";

const initialState = {
  isLoading: false,
};

export const ResetPasswordAction = createAsyncThunk(
  "ResetPassword/ResetPasswordAction",
  async (payload, thunkApi) => {
    const res = await apiCall(
      "/auth/reset-password",
      apiCallTypes.post,
      payload,
      false
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const ResetPasswordSlice = createSlice({
  name: "ResetPasswordReducer",
  initialState,
  extraReducers: {
    [ResetPasswordAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      SnackBar("success", payload.data?.message);
    },
    [ResetPasswordAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [ResetPasswordAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default ResetPasswordSlice.reducer;
