import { Modal, Accordion } from "react-bootstrap";
import RadioImg from "Assets/Images/icons/radio.svg";
import WorngAns from "Assets/Images/new-assets/red.png";
import CorrectAns from "Assets/Images/new-assets/green.png";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const AnswersModal = (props) => {
  const { t } = useTranslation();
  const { questions } = useSelector((state) => state.LevelReducer);

  const answerImage = (question, answer) => {
    if (question?.selected_answer?.some((ans) => ans?.id === answer?.id)) {
      if (answer?.is_correct) {
        return CorrectAns;
      } else {
        return WorngAns;
      }
    } else {
      return RadioImg;
    }
  };

  const correctAnswer = (question) => {
    const correctAnswer = question?.answers?.filter(
      (answer) => answer?.is_correct
    );
    return `${
      correctAnswer.length <= 1
        ? t("Answers.CorrectAnswer")
        : t("Answers.CorrectAnswers")
    } ${correctAnswer.map((correctAnswer) => correctAnswer.title).join(", ")}`;
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="answers-modal"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={false}
      centered
    >
      <Modal.Header className="text-center" closeButton>
        <Modal.Title>
          <h2>{t("Answers.Title")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          {questions?.map((question, index) => (
            <Accordion.Item eventKey={question?.id} key={question?.id}>
              <Accordion.Header>
                <span>{index + 1}</span>
                <div
                  className="question"
                  dangerouslySetInnerHTML={{ __html: question?.description }}
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="answers-wrapper">
                  <div className="answers">
                    {question?.answers?.map((answer) => (
                      <div className="options" key={answer?.id}>
                        <img src={answerImage(question, answer)} alt="" />
                        <span>{answer?.title}</span>
                      </div>
                    ))}
                  </div>
                  <div className="points">
                    {t("Answers.Points")}: {question?.score}
                  </div>
                </div>
                <div className="correct-answer">
                  ({correctAnswer(question)})
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

export default AnswersModal;
