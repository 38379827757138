import * as _ from "lodash";
import { SnackBar } from "Components/Common";
import { t } from "i18next";
import apiCall from "Middlewares/ApiClient";
import {
  apiCallTypes,
  DIJKLANDER_ACCESS_TOKEN,
  EMAIL_DOMAIN,
  emailRegex,
  ranks,
} from "Constants";
import router from "Routes";

export const isLoggedIn = () => !!getToken();
// export const isLoggedIn = () => true;

/**
 *
 * @returns
 */
// export function isLoggedIn() {
//   /*
//    * Note:
//    *  This app assume if local storage have roles it means
//    *  user is authenticated you can update this logic as per your app.
//    */
//
//   // return !!localStorage.getItem('user');
//   return !_.isNull(localStorage.getItem(DIJKLANDER_ACCESS_TOKEN))
//     ? localStorage.getItem(DIJKLANDER_ACCESS_TOKEN)
//     : false;
//   // return !!localStorage.getItem('roles');
// }

/**
 *
 */
export async function logout(apiClient = false) {
  if (!apiClient) {
    await apiCall(`/auth/logout`, apiCallTypes.post, null, true);
  }
  localStorage.removeItem(DIJKLANDER_ACCESS_TOKEN);
  router.navigate("/");
}

/**
 * This method is used to get Bearer Token
 * @returns {string|null}
 */
export const getToken = () => {
  if (_.isNull(localStorage.getItem(DIJKLANDER_ACCESS_TOKEN))) return null;
  return `Bearer ${
    JSON.parse(localStorage.getItem(DIJKLANDER_ACCESS_TOKEN)).access_token
  }`;
};

/**
 * This method is used to save Auth Token
 * @param token
 * @returns {void|boolean}
 */
export const saveAuthToken = (token) =>
  token
    ? localStorage.setItem(DIJKLANDER_ACCESS_TOKEN, JSON.stringify(token))
    : false;

/**
 * This method is used to convert params to Query String.
 * @param paramsObject
 * @returns {string}
 */
export const toQueryString = (paramsObject) =>
  Object.keys(paramsObject)
    .map(
      (key) =>
        `${encodeURI(key)}=${encodeURI(JSON.stringify(paramsObject[key]))}`
    )
    .join("&");

export const getAllowedRoutes = (routes) => {
  // const getUser = localStorage.getItem("roles");
  // const getUsers = JSON.parse(localStorage.getItem("user"));
  // const role = !_.isNull(getUsers) ? getUsers.user.userRole : null;
  const role = "ADMIN";
  // console.log(role);
  // const roles = JSON.parse(getUser);
  return routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else {
      return _.intersection(permission, [role]).length;
    }
  });
};

export const isArrayWithLength = (arr) => {
  return Array.isArray(arr) && arr.length;
};

export const validationError = (error) => {
  console.log(error);
  if (error) {
    // if (typeof error === "string") {
    //   SnackBar("error", t("Errors.Validation.Title", { cause: error }));
    //   return;
    // }

    // if (error instanceof HttpErrorResponse) {
    if (typeof error === "string") {
      SnackBar("error", t("Errors.Validation.Title", { cause: error }));
      return;
    }

    if (typeof error === "object") {
      if ("errors" in error) {
        const keys = Object.keys(error.errors);
        if (keys.length) {
          const value = keys
            .map((key) => error.errors[key])
            .map((valueToMap) =>
              Array.isArray(valueToMap) ? valueToMap[0] : valueToMap
            )
            .filter(
              (valueToFilter) =>
                valueToFilter && typeof valueToFilter === "string"
            )
            .shift();

          if (value) {
            SnackBar("error", t("Errors.Validation.Title", { cause: value }));
            return;
          }
        }
      }

      if ("message" in error) {
        SnackBar(
          "error",
          t("Errors.Validation.Title", { cause: error.message })
        );
        return;
      }
      return;
    } else {
      SnackBar("error", t("Errors.ServerValidation"));
    }

    // return;
    // }

    // if (error instanceof Error && error.message) {
    //   SnackBar("error", t("Errors.Validation.Title", { cause: error.message }));
    //   return;
    // }
  }
  SnackBar("error", t("Errors.GenericValidation"));
};

export const appendEmailDomain = (payload) => {
  const modifiedPayload = { ...payload };
  if (!emailRegex.test(modifiedPayload.email))
    modifiedPayload.email = modifiedPayload.email + "@" + EMAIL_DOMAIN;
  return modifiedPayload;
};

export const getRank = (...args) => {
  const [index, page, perPage, isRank] = args;
  const value = index + 1 + (page - 1) * perPage;
  if (isRank) return ranks[value] || value;
  return value;
};

export const getPercentage = (current, total) =>
  (parseInt(current) / parseInt(total)) * 100;

export const getCompletedBadges = (badges, totalScore) =>
  badges.map((badge) => ({
    ...badge,
    completed: parseInt(totalScore) >= parseInt(badge.ending_score),
  }));
