import { useEffect, useRef, useState } from "react";
import "./style.scss";
import { InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "Assets/Images/Auth/girl-img1.svg";
import Image2 from "Assets/Images/Auth/girl-img3.svg";
import { Field, Form, Formik } from "formik";
import { forgotPasswordSchema } from "Utils/ValidationSchemas";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ForgotPasswordAction } from "Redux/Auth/ForgotPasswordReducer";
import { ErrorMessageComponent, VerificationModal } from "Components";
import { EMAIL_DOMAIN, FORGOT_PASSWORD } from "Constants";
import { appendEmailDomain } from "Utils";
import { setLoader } from "Redux/CommonReducer";

const ForgotPassword = () => {
  const [modalShow, setModalShow] = useState(false);
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.ForgotPasswordReducer);
  const { t } = useTranslation();
  const formikRef = useRef();
  const initialValues = {
    email: "",
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    const res = await dispatch(ForgotPasswordAction(appendEmailDomain(values)));
    setErrors(res.payload.errors);
    setSubmitting(false);
    if (!res.payload.error) {
      setModalShow(true);
      setEmail(values.email);
      resetForm();
    }
  };

  return (
    <>
      <div className="auth-bg-img forgot-page">
        <div className="auth-page">
          <div className="d-flex justify-content-center align-items-end mvh-100">
            <div className="left-girl">
              <img src={Image} alt="img-1" />
            </div>
            <div className="authform">
              <div className="auth-form-box bg-signup rounded">
                <div className="box-header text-center">
                  <h2>{t("ForgotPassword.Title")}</h2>
                  <p className="text-white fs-20">
                    {t("ForgotPassword.Header")}
                  </p>
                </div>
                <div className="authbox-content">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={handleSubmit}
                    innerRef={formikRef}
                  >
                    <Form>
                      <label htmlFor="email">{t("Login.Form.Email")}</label>
                      <InputGroup>
                        <Field name="email" type="text" />
                        <InputGroup.Text id="basic-addon2">
                          @{EMAIL_DOMAIN}
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessageComponent
                        name="email"
                        component="div"
                        className="validation-message"
                      />

                      <Button variant="primary" type="submit">
                        {t("Buttons.Submit")}
                      </Button>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="bottom-link">
                <Link to="/">Back to Login</Link>
              </div>
            </div>
            <div className="right-girl">
              <img src={Image2} alt="img-3" />
            </div>
          </div>
        </div>
      </div>
      <VerificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        email={email}
        type={FORGOT_PASSWORD}
      />
    </>
  );
};

export default ForgotPassword;
