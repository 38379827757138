import {
  Categories,
  Home,
  Information,
  Introduction,
  Leaderboard,
  Profile,
  Questions,
  Result,
} from "Pages";
import { LevelsComponent } from "Components";

const PrivateRoutes = [
  {
    component: <Home />,
    path: "home",
  },
  {
    component: <Leaderboard />,
    path: "leaderboard",
  },
  {
    component: <Profile />,
    path: "profile",
  },
  {
    component: <Categories />,
    path: "categories",
    children: [{ component: <LevelsComponent />, path: ":id" }],
  },
  {
    component: <Introduction />,
    path: "introduction/:id",
  },
  {
    component: <Questions />,
    path: "questions/:id",
  },
  {
    component: <Information />,
    path: "information/:id",
  },
  {
    component: <Result />,
    path: "result/:id",
  },
  // {
  //   component: <Login />,
  //   path: "login",
  //   permission: ["USER"],
  //   children: [
  //     {
  //       component: <Test />,
  //       path: "test",
  //       children: [{ component: <Login />, path: "about" }],
  //     },
  //     {
  //       component: <Login />,
  //       path: "about",
  //     },
  //   ],
  // },
];

export default PrivateRoutes;
