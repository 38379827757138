import {
  Container,
  Row,
  Col,
  ProgressBar,
  Dropdown,
  Button,
} from "react-bootstrap";
import "./style.scss";
import userIcon from "Assets/Images/profile-icon.svg";
import RankBadge from "Assets/Images/Home/rank-badge.png";
import { BadgesModal, Footer } from "Components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPercentage, logout } from "Utils";
import { HomeAction } from "Redux/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "Redux/CommonReducer";
import snackBar from "Components/Common/SnackBar";

const Home = () => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, home } = useSelector((state) => state.ProfileReducer);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(HomeAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  const playNavigation = (e) => {
    e.preventDefault();
    if (home?.is_organization_assigned) navigate("/app/categories");
    else snackBar("error", t("Home.Error"));
  };

  return (
    <>
      <div className="homepage">
        <Container fluid className="p-0">
          <Row className="home-pt">
            <Col sm="6" className="p-0">
              <div className="score-wrapper">
                <div className="badge-header d-flex align-items-center">
                  <img
                    className="score-badge"
                    onClick={() => setModalShow(true)}
                    src={home?.profile?.current_badge?.icon?.url}
                    alt="badge"
                  />
                  <div className="custom-progress">
                    <ProgressBar
                      now={
                        !home?.profile?.current_badge?.ending_score
                          ? 100
                          : getPercentage(
                              home?.profile?.total_score,
                              home?.profile?.current_badge?.ending_score
                            )
                      }
                    />
                  </div>
                  <div className="score">
                    <span>{home?.profile?.total_score}</span>
                    {!!home?.profile?.current_badge?.ending_score && (
                      <span>/{home?.profile?.current_badge?.ending_score}</span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="6" className="p-0">
              <div className="user-info">
                <div className="username-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {home?.profile?.user_name} <img src={userIcon} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate("/app/profile")}>
                        {t("Profile.Title")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => logout()}>
                        {t("Home.Logout")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
            <Col sm="12" className="p-0">
              <div className="ranking">
                <img
                  src={RankBadge}
                  onClick={() => navigate("/app/leaderboard")}
                  alt=""
                />
                <div className="rank-score">
                  <span>{t("Home.Rank")}</span>{" "}
                  <strong>{home?.profile?.curent_rank}</strong>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="p-0" fluid>
          <Row className="footer">
            <Col sm="12">
              <div className="text-center play-button">
                <Button className="play" onClick={playNavigation}>
                  {t("Home.Play")}
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="footer">
            <Col className="p-0" lg="12">
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
      <BadgesModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Home;
