import { useState, useEffect, useRef } from "react";
import "./style.scss";
import { InputGroup, Button } from "react-bootstrap";
import Image from "Assets/Images/Auth/girl-img1.svg";
import Image2 from "Assets/Images/Auth/girl-img3.svg";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { registerSchema } from "Utils/ValidationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { getRoles, registerUser } from "Redux/Auth/RegisterReducer";
import { setLoader } from "Redux/CommonReducer";
import { useTranslation } from "react-i18next";
import { ErrorMessageComponent, VerificationModal } from "Components";
import { EMAIL_DOMAIN, EMAIL_VERIFICATION } from "Constants";
import { appendEmailDomain } from "Utils";

const Register = () => {
  const [modalShow, setModalShow] = useState(false);
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formikRef = useRef();
  const { isLoading, roles } = useSelector((state) => state.RegisterReducer);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);
  useEffect(() => {
    dispatch(setLoader(isLoading));
    if (!isLoading) {
      formikRef.current.values.role = roles[0]?.id;
    }
  }, [dispatch, isLoading, roles]);

  const initialValues = {
    role: "",
    email: "",
    user_name: "",
    password: "",
    password_confirmation: "",
  };

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    const res = await dispatch(registerUser(appendEmailDomain(values)));
    setErrors(res.payload.errors);
    setSubmitting(false);
    if (!res.payload.error) {
      setModalShow(true);
      setEmail(values.email);
      resetForm();
    }
  };

  return (
    <>
      <div className="auth-bg-img register-page">
        <div className="auth-page">
          <div className="d-flex justify-content-center align-items-end mvh-100">
            <div className="left-girl">
              <img src={Image} alt="img-1" />
            </div>
            <div className="form">
              <div className="auth-form-box bg-signup rounded">
                <div className="box-header text-center">
                  <h2>{t("Register.Title")}</h2>
                </div>
                <div className="authbox-content">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={registerSchema}
                    onSubmit={handleSubmit}
                    innerRef={formikRef}
                  >
                    <Form>
                      <label htmlFor="role">
                        {t("Register.Form.UserRole")}
                      </label>
                      <Field as="select" name="role">
                        {roles.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.title}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessageComponent name="role" />

                      <label htmlFor="email">{t("Register.Form.Email")}</label>
                      <InputGroup>
                        <Field name="email" type="text" />
                        <InputGroup.Text id="basic-addon2">
                          @{EMAIL_DOMAIN}
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessageComponent name="email" />

                      <label htmlFor="user_name">
                        {t("Register.Form.Username")}
                      </label>
                      <Field type="text" name="user_name" />
                      <ErrorMessageComponent name="user_name" />

                      <label htmlFor="password">
                        {t("Register.Form.Password")}
                      </label>
                      <Field
                        type="password"
                        name="password"
                        onChange={(e) => {
                          formikRef.current.handleChange(e);
                          formikRef.current.setFieldValue(
                            "password_confirmation",
                            ""
                          );
                        }}
                      />
                      <ErrorMessageComponent name="password" />

                      <label htmlFor="password_confirmation">
                        {t("Register.Form.ConfirmPassword")}
                      </label>
                      <Field type="password" name="password_confirmation" />
                      <ErrorMessageComponent name="password_confirmation" />

                      <Button variant="primary" type="submit">
                        {t("Buttons.Create")}
                      </Button>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="bottom-link text-center">
                <Link to="/">{t("Register.LoginLink")}</Link>
              </div>
            </div>
            <div className="right-girl">
              <img src={Image2} alt="img-3" />
            </div>
          </div>
        </div>
      </div>
      <VerificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        email={email}
        type={EMAIL_VERIFICATION}
      />
    </>
  );
};

export default Register;
