import * as Yup from "yup";
import { t } from "i18next";
import { EMAIL_DOMAIN, passwordRegex } from "../Constants";
const emailValidation = (val) =>
  !(
    (val.split("@")[1] && val.split("@")[1] !== EMAIL_DOMAIN) ||
    val.includes(" ")
  );

export const loginSchema = Yup.object({
  email: Yup.string()
    .required(t("ValidationMessage.Required", { label: "Email" }))
    .test(
      "emailValidation",
      t("ValidationMessage.Email", { label: "Email" }),
      emailValidation
    ),
  password: Yup.string().required(
    t("ValidationMessage.Required", { label: "Password" })
  ),
});

export const registerSchema = Yup.object({
  role: Yup.string().required(
    t("ValidationMessage.Required", { label: "User role" })
  ),
  user_name: Yup.string().required(
    t("ValidationMessage.Required", { label: "Username" })
  ),
  email: Yup.string()
    .required(t("ValidationMessage.Required", { label: "Email" }))
    .test(
      "emailValidation",
      t("ValidationMessage.Email", { label: "Email" }),
      emailValidation
    ),
  password: Yup.string()
    .required(t("ValidationMessage.Required", { label: "Password" }))
    .matches(new RegExp(passwordRegex), t("ValidationMessage.Password")),
  password_confirmation: Yup.string()
    .required(
      t("ValidationMessage.Required", { label: "Password confirmation" })
    )
    .oneOf(
      [Yup.ref("password")],
      t("ValidationMessage.Confirmed", {
        label: "Confirm Password",
        related: "Password",
      })
    ),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .required(t("ValidationMessage.Required", { label: "Email" }))
    .test(
      "emailValidation",
      t("ValidationMessage.Email", { label: "Email" }),
      emailValidation
    ),
});
export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required(t("ValidationMessage.Required", { label: "Password" }))
    .matches(new RegExp(passwordRegex), t("ValidationMessage.Password")),
  password_confirmation: Yup.string()
    .required(
      t("ValidationMessage.Required", { label: "Password confirmation" })
    )
    .oneOf(
      [Yup.ref("password")],
      t("ValidationMessage.Confirmed", {
        label: "Confirm Password",
        related: "Password",
      })
    ),
});

export const changePasswordSchema = Yup.object({
  old_password: Yup.string().required(
    t("ValidationMessage.Required", { label: "Old Password" })
  ),
  password: Yup.string()
    .required(t("ValidationMessage.Required", { label: "Password" }))
    .matches(new RegExp(passwordRegex), t("ValidationMessage.Password")),
  password_confirmation: Yup.string()
    .required(
      t("ValidationMessage.Required", { label: "Password confirmation" })
    )
    .oneOf(
      [Yup.ref("password")],
      t("ValidationMessage.Confirmed", {
        label: "Confirm Password",
        related: "Password",
      })
    ),
});
