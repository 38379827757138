import { Row, Col, Button } from "react-bootstrap";
import "./style.scss";
import HomeIcon from "Assets/Images/new-assets/home.png";
import CrossIcon from "Assets/Images/new-assets/Cross-lg.png";
import { ErrorMessageComponent, Footer } from "Components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { changePasswordSchema } from "Utils/ValidationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordAction, ProfileAction } from "Redux/ProfileReducer";
import { useEffect } from "react";
import { setLoader } from "../../Redux/CommonReducer";

const Profile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, user } = useSelector((state) => state.ProfileReducer);

  const initialValues = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  useEffect(() => {
    dispatch(ProfileAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    const res = await dispatch(ChangePasswordAction(values));
    setErrors(res.payload.errors);
    setSubmitting(false);
    if (!res.payload.error) {
      resetForm();
    }
  };

  return (
    <div className="profile-page">
      <div className="header">
        <img
          src={HomeIcon}
          alt="home-icon"
          onClick={() => navigate("/app/home")}
        />
        <div className="title">
          <h2>{t("Profile.Title")}</h2>
        </div>
        <img
          src={CrossIcon}
          alt="cross-icon"
          onClick={() => navigate("/app/home")}
        />
      </div>
      <div className="page-body">
        <div className="primary-bg-border first-box">
          <div className="title-blue">{t("Profile.UserInfo.Header")}</div>
          <div className="user-data">
            <div>{t("Profile.UserInfo.Username")}</div>
            <div className="text-right">{user?.user_name}</div>
          </div>
          <div className="user-data">
            <div>{t("Profile.UserInfo.Email")}</div>
            <div className="text-right">{user?.email}</div>
          </div>
          <div className="user-data">
            <div>{t("Profile.UserInfo.Organization")}</div>
            <div className="text-right">{user?.organization?.name}</div>
          </div>
          <div className="user-data">
            <div>{t("Profile.UserInfo.Department")}</div>
            <div className="text-right">{user?.department?.name}</div>
          </div>
          <div className="user-data">
            <div>{t("Profile.UserInfo.Team")}</div>
            <div className="text-right">{user?.team?.name}</div>
          </div>
        </div>

        <div className="primary-bg-border mt-1">
          <div className="title-blue">{t("Profile.ChangePassword.Header")}</div>

          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Row className="form-row">
                <Col className="ps-0">
                  <label>{t("Profile.ChangePassword.OldPassword")}</label>
                  <Field type="password" name="old_password" />
                  <ErrorMessageComponent name="old_password" />
                </Col>

                <Col>
                  <label>{t("Profile.ChangePassword.NewPassword")}</label>
                  <Field type="password" name="password" />
                  <ErrorMessageComponent name="password" />
                </Col>

                <Col>
                  <label>{t("Profile.ChangePassword.ConfirmPassword")}</label>
                  <Field type="password" name="password_confirmation" />
                  <ErrorMessageComponent name="password_confirmation" />
                </Col>

                <Col className="pe-0">
                  <Button variant="primary" type="submit">
                    {t("Buttons.Update")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
