import apiCall from "Middlewares/ApiClient";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCallTypes } from "Constants";
import { SnackBar } from "Components/Common";

const initialState = {
  isLoading: false,
};

export const ForgotPasswordAction = createAsyncThunk(
  "ForgotPassword/ForgotPasswordAction",
  async (payload, thunkApi) => {
    const res = await apiCall(
      "/auth/forgot",
      apiCallTypes.post,
      payload,
      false
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const ForgotPasswordSlice = createSlice({
  name: "ForgotPasswordReducer",
  initialState,
  extraReducers: {
    [ForgotPasswordAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      SnackBar("success", payload.data?.message);
    },
    [ForgotPasswordAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [ForgotPasswordAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default ForgotPasswordSlice.reducer;
