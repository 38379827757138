import Lottie from "lottie-react";
import phoneRotate from "Assets/Images/json/PhoneRotate.json";
import "./style.scss";
import { useTranslation } from "react-i18next";

const RotatePhone = () => {
  const { t } = useTranslation();

  return (
    <div className="rotate-wrapper">
      <div className="animation">
        <Lottie animationData={phoneRotate} />
      </div>
      <div className="rotate-text">{t("RotatePhone.Header")}</div>
    </div>
  );
};

export default RotatePhone;
