import "./style.scss";
import { useState, useEffect, useCallback } from "react";
import { AnswersModal, Footer } from "Components";
import { useNavigate, useParams } from "react-router-dom";
import HomeIcon from "Assets/Images/new-assets/home.png";
import trophyImg from "Assets/Images/icons/trophy.svg";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ResultAction } from "Redux/LevelReducer";
import { setLoader } from "Redux/CommonReducer";
import { useTranslation } from "react-i18next";

const Result = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, result, categoryId } = useSelector(
    (state) => state.LevelReducer
  );
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    dispatch(ResultAction(params?.id));
  }, [dispatch, params?.id]);

  const navigation = useCallback(
    (e) => {
      e.preventDefault();
      navigate(
        categoryId ? `/app/categories/${categoryId}` : "/app/categories",
        {
          replace: true,
        }
      );
    },
    [navigate, categoryId]
  );

  useEffect(() => {
    if (categoryId !== undefined) window.onpopstate = (e) => navigation(e);
    return () => (window.onpopstate = null);
  }, [categoryId, navigate, navigation]);

  return (
    <>
      <div className="main-bg result-page">
        <header>
          <Container className="p-0" fluid>
            <div className="header-wrapper">
              <img src={HomeIcon} alt="" onClick={navigation} />
              <div className="page-title">
                <h2>{t("Result.Title")}</h2>
              </div>
            </div>
          </Container>
        </header>
        <div className="content">
          <Container>
            <div className="result-content text-center">
              <div className="trophy">
                <img src={trophyImg} alt="trophy" />
              </div>
              <h2>{t("Result.Congratulations")}</h2>
              <h1>
                {result?.earned_score}/{result?.total_score} {t("Result.Score")}
              </h1>
              <h3>{t("Result.QuizCompleted")}</h3>
              <p>
                {t("Result.Attempt.1")}{" "}
                <strong>{result?.attempted_questions}</strong>{" "}
                {result?.attempted_questions < 2
                  ? t("Result.Attempt.2")
                  : t("Result.Attempt.3")}{" "}
                {t("Result.Attempt.4")} <br />
                <strong>{result?.correct_answers}</strong>{" "}
                {t("Result.Attempt.5")}{" "}
                {result?.correct_answers > 1
                  ? t("Result.Attempt.6")
                  : t("Result.Attempt.7")}{" "}
                {t("Result.Attempt.8")}
              </p>
            </div>
          </Container>
        </div>
        <footer>
          <div className="footer">
            <Row>
              <Col sm="12">
                <div className="text-center actions-button">
                  <Button
                    className="outline"
                    onClick={() => setModalShow(true)}
                  >
                    {t("Result.Answers")}
                  </Button>
                  <Button className="solid" onClick={navigation}>
                    {t("Result.PlayNextLevel")}
                  </Button>
                </div>
              </Col>
            </Row>
            <Footer />
          </div>
        </footer>
      </div>
      <AnswersModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        fullscreen={modalShow}
      />
    </>
  );
};

export default Result;
