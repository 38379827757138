import { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { Footer } from "Components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import HomeIcon from "Assets/Images/new-assets/list-view.png";
import HintIcon from "Assets/Images/new-assets/hint.png";
import PlayBtn from "Assets/Images/categories/play.svg";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LevelAction } from "Redux/LevelReducer";
import { setLoader } from "Redux/CommonReducer";
import { QuestionAction } from "Redux/QuestionReducer";
import { getPercentage } from "Utils";
import ReactPlayer from "react-player/lazy";

const Questions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { level, isLoading } = useSelector((state) => state.LevelReducer);
  const [answer, setAnswer] = useState(null);
  const [question, setQuestion] = useState(null);

  const getQuestionIndex = useCallback(
    () =>
      level?.questions?.findIndex((item) => item?.id === parseInt(params?.id)),
    [level?.questions, params?.id]
  );

  useEffect(() => {
    dispatch(LevelAction(searchParams.get("levelId")));
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
    setQuestion(level?.questions[getQuestionIndex()]);
    setAnswer(level?.questions[getQuestionIndex()]?.answers);
  }, [dispatch, isLoading, params?.id, level, getQuestionIndex]);

  const handleChange = (e) => {
    setAnswer(
      answer.map((item) =>
        item.id === parseInt(e.target.value)
          ? {
              ...item,
              is_checked: e.target.checked,
            }
          : item
      )
    );
  };

  const previousQuestion = (e) => {
    e.preventDefault();
    if (getQuestionIndex() > 0) {
      navigate(
        `/app/questions/${
          level?.questions[getQuestionIndex() - 1]?.id
        }?levelId=${level?.id}`
      );
    }
  };

  const nextQuestion = () => {
    if (getQuestionIndex() < level?.questions?.length - 1) {
      navigate(
        `/app/questions/${
          level?.questions[getQuestionIndex() + 1]?.id
        }?levelId=${level?.id}`
      );
      dispatch(LevelAction(level?.id));
    } else {
      navigate(`/app/result/${level?.id}`, { replace: true });
      dispatch(setLoader(false));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!answer) return;
    dispatch(setLoader(true));
    const res = await dispatch(
      QuestionAction({
        level: level?.id,
        question: question?.id,
        answer: answer,
      })
    );
    if (!res.payload.error) {
      nextQuestion();
    } else {
      dispatch(setLoader(false));
    }
  };

  return (
    <div
      className="main-bg questions-page"
      style={{
        ...(level?.background_image?.url && {
          backgroundImage: `url(${level?.background_image?.url})`,
        }),
      }}
    >
      <header>
        <Container className="p-0" fluid>
          <div className="header-wrapper">
            <img
              src={HomeIcon}
              alt=""
              onClick={() => navigate("/app/categories")}
            />
            <div className="progess-wrapper">
              <h3
                className="questions-count"
                onClick={() => console.log(answer)}
              >
                {t("Question.title")} {getQuestionIndex() + 1}/
                {level?.questions?.length}
              </h3>
              <ProgressBar
                now={getPercentage(
                  getQuestionIndex(),
                  level?.questions?.length
                )}
              />
            </div>
            {!!question?.is_info_pages_exists && (
              <img
                src={HintIcon}
                alt=""
                onClick={() =>
                  navigate(
                    `/app/information/${question?.id}?levelId=${level?.id}`
                  )
                }
              />
            )}
          </div>
        </Container>
      </header>
      <div className="content">
        <div className="question-box">
          {!!(question?.media || question?.url) && (
            <div className="video-box">
              {question?.media ? (
                <img
                  className="cover"
                  src={question?.media?.url}
                  alt="video-cover"
                />
              ) : (
                <ReactPlayer
                  url={question?.url}
                  playIcon={
                    <img className="playbutton" src={PlayBtn} alt="Play" />
                  }
                  light
                  controls
                />
              )}
            </div>
          )}
          <div
            className="text-question"
            dangerouslySetInnerHTML={{ __html: question?.description }}
          />
        </div>
        <Form>
          <Row className="options-row">
            {answer?.map((item) => (
              <Col sm="6" key={item?.id}>
                <Form.Check
                  inline
                  label={item?.title}
                  name={item?.title}
                  type="checkbox"
                  value={item?.id}
                  onChange={handleChange}
                  id={item?.id}
                  checked={item?.is_checked}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col sm="12">
              <div className="text-center actions-button">
                <Button
                  className="outline"
                  onClick={previousQuestion}
                  disabled={getQuestionIndex() <= 0}
                >
                  {t("Buttons.Back")}
                </Button>
                <Button
                  className="solid"
                  onClick={handleSubmit}
                  disabled={!answer?.some((item) => item?.is_checked)}
                >
                  {getQuestionIndex() >= level?.questions?.length - 1
                    ? t("Buttons.Finish")
                    : t("Buttons.Next")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <footer>
        <div className="footer">
          <Footer />
        </div>
      </footer>
    </div>
  );
};

export default Questions;
