import { combineReducers } from "@reduxjs/toolkit";
import LoginReducer from "./Auth/LoginReducer";
import RegisterReducer from "./Auth/RegisterReducer";
import ForgotPasswordReducer from "./Auth/ForgotPasswordReducer";
import ResetPasswordReducer from "./Auth/ResetPasswordReducer";
import CommonReducer from "./CommonReducer";
import ProfileReducer from "./ProfileReducer";
import CategoryReducer from "./CategoryReducer";
import LevelReducer from "./LevelReducer";
import QuestionReducer from "./QuestionReducer";
import LeaderboardReducer from "./LeaderboardReducer";

const Reducers = {
  LoginReducer,
  RegisterReducer,
  ForgotPasswordReducer,
  ResetPasswordReducer,
  CommonReducer,
  ProfileReducer,
  CategoryReducer,
  LevelReducer,
  QuestionReducer,
  LeaderboardReducer,
};

export default combineReducers(Reducers);
