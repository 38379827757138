import apiCall from "Middlewares/ApiClient";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCallTypes, perPage } from "Constants";

const initialState = {
  isLoading: false,
  categories: {
    data: [],
    meta: {},
  },
  levels: {
    data: [],
    meta: { total: 1 },
  },
};

export const CategoryAction = createAsyncThunk(
  "Category/CategoryAction",
  async (payload, thunkApi) => {
    const res = await apiCall(
      "/my/categories/list",
      apiCallTypes.get,
      null,
      true,
      { page: payload, per_page: perPage }
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const LevelsAction = createAsyncThunk(
  "Category/LevelsAction",
  async (payload, thunkApi) => {
    const res = await apiCall(
      `/my/categories/${payload.id}/levels`,
      apiCallTypes.get,
      null,
      true,
      { page: payload.page, per_page: payload.perPage }
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const CategorySlice = createSlice({
  name: "CategoryReducer",
  initialState,
  extraReducers: {
    [CategoryAction.fulfilled]: (state, { payload }) => {
      if (payload?.data?.meta?.current_page === 1) {
        state.categories.data = payload?.data?.data;
      } else {
        state.categories.data = [
          ...state.categories.data,
          ...(payload?.data?.data || {}),
        ];
      }
      state.categories.meta = payload?.data?.meta;
      state.isLoading = false;
    },
    [CategoryAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [CategoryAction.pending]: (state) => {
      state.isLoading = true;
    },
    [LevelsAction.fulfilled]: (state, { payload }) => {
      [state.levels.data, state.levels.meta, state.isLoading] = [
        payload?.data?.data,
        payload?.data?.meta,
        false,
      ];
    },
    [LevelsAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [LevelsAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default CategorySlice.reducer;
