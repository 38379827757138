import { getToken, logout, isLoggedIn } from "Utils";
import axios from "axios";
import { SnackBar } from "Components/Common";
import { t } from "i18next";

const API_URL = process.env.REACT_APP_BASE_URL;

const apiCall = async (
  endpoint,
  type,
  payload = null,
  isPrivate = isLoggedIn(),
  params = null
) => {
  const response = {
    data: null,
    error: false,
    errors: {},
  };

  const config = {
    method: type,
    url: API_URL + endpoint,
  };

  if (payload !== null) config.data = payload;

  if (isPrivate)
    config.headers = {
      authorization: getToken(),
    };

  if (params) config.params = params;

  try {
    const res = await axios(config);
    response.data = res.data;
    response.error = false;
    return response;
  } catch (error) {
    if (error.isAxiosError && !error.response) {
      SnackBar("error", t("Errors.Server"));
    } else {
      switch (error.response.status) {
        case 0:
          SnackBar("error", t("Errors.Network"));
          break;
        case 401:
          // SnackBar("error", t("Errors.Authentication"));
          SnackBar("error", error.response?.data?.message);
          logout(true);
          break;
        case 403:
          SnackBar("error", t("Errors.Forbidden"));
          break;
        case 404:
          SnackBar("error", t("Errors.NotFound"));
          break;
        case 422:
          // validationError(error.response.data);
          SnackBar("error", t("Errors.Validation.Message"));
          break;
        case 429:
          SnackBar("error", t("Errors.Throttle"));
          break;
        default:
          SnackBar("error", error.response.data.message);
      }
    }

    // else if (error.response.status === 401) {
    //   logout();
    // } else if (error.response.status >= 500) {
    //   SnackBar("error", t("Errors.InternalServer"));
    //   // "Internal server error"
    // } else if (error.response.status === 404) {
    //   SnackBar("error", "Not Found");
    //   // if (!noRedirect) {
    //   //   history.push("/app/not-found");
    //   // }
    // } else if (error.response.status === 403) {
    //   SnackBar("error", t("Errors.InternalServer"));
    //   // if (!noRedirect) {
    //   //   history.push("/app/dashboard");
    //   // }
    // } else {
    //   SnackBar("error", error.response.data.message);
    // }
    response.error = true;
    response.errors = error.response.data.errors
      ? error.response.data.errors
      : [];
    return response;
  }
};

export default apiCall;
