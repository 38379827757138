import "./style.scss";
import { useEffect } from "react";
import { Footer } from "Components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PlayBtn from "Assets/Images/categories/play.svg";
import { Row, Col, Tab, Tabs, Carousel } from "react-bootstrap";
import CrossIcon from "Assets/Images/new-assets/Cross-lg.png";
import { useDispatch, useSelector } from "react-redux";
import { QuestionInfoAction } from "Redux/QuestionReducer";
import { useTranslation } from "react-i18next";
import { setLoader } from "Redux/CommonReducer";
import ReactPlayer from "react-player/lazy";

const Information = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { backgroundImage, infoPages, isLoading } = useSelector(
    (state) => state.QuestionReducer
  );

  useEffect(() => {
    dispatch(
      QuestionInfoAction({
        level: searchParams.get("levelId"),
        question: params?.id,
      })
    );
  }, [dispatch, params?.id, searchParams]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  return (
    <div
      className="main-bg information-page"
      style={{
        ...(backgroundImage?.url && {
          backgroundImage: `url(${backgroundImage?.url})`,
        }),
      }}
    >
      <div className="header">
        <div className="title">
          <h2>{t("Information.Title")}</h2>
        </div>
        <img
          src={CrossIcon}
          alt=""
          onClick={() =>
            navigate(
              `/app/questions/${params?.id}?levelId=${searchParams.get(
                "levelId"
              )}`
            )
          }
        />
      </div>
      <div className="page-content">
        <Tabs
          defaultActiveKey={infoPages[0]?.id}
          transition={false}
          id="tabs-info"
        >
          {infoPages.map((page) => (
            <Tab eventKey={page?.id} title={page?.name} key={page?.id}>
              <Row>
                <Col
                  sm={
                    (page?.urls?.length || page?.content_images?.length) > 0
                      ? 8
                      : 12
                  }
                  className="ps-0"
                >
                  {page?.is_external_link ? (
                    <a href={page?.external_link}>{page?.external_link}</a>
                  ) : (
                    <div
                      className="tab-text"
                      dangerouslySetInnerHTML={{
                        __html: page?.content,
                      }}
                    />
                  )}
                </Col>
                {(page?.urls?.length || page?.content_images?.length) > 0 && (
                  <Col sm="4" className="pe-0">
                    <div className="sliderbox">
                      <Carousel controls={false} interval={null}>
                        {page?.urls?.map((item) => (
                          <Carousel.Item key={item}>
                            <div className="video-box">
                              <ReactPlayer
                                url={item}
                                playIcon={
                                  <img
                                    className="playbutton"
                                    src={PlayBtn}
                                    alt="Play"
                                  />
                                }
                                light
                                controls
                              />
                            </div>
                          </Carousel.Item>
                        ))}
                        {page?.content_images?.map((item) => (
                          <Carousel.Item key={item?.id}>
                            <div className="video-box">
                              <img
                                className="cover"
                                src={item?.url}
                                alt="video-cover"
                              />
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                  </Col>
                )}
              </Row>
            </Tab>
          ))}
        </Tabs>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Information;
