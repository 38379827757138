import "./style.scss";
import { Button, InputGroup } from "react-bootstrap";
import Image from "Assets/Images/Auth/girl-img1.svg";
import Image2 from "Assets/Images/Auth/girl-img3.svg";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { loginSchema } from "Utils/ValidationSchemas";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { setLoader } from "Redux/CommonReducer";
import { LoginAction } from "Redux/Auth/LoginReducer";
import { appendEmailDomain } from "Utils";
import { EMAIL_DOMAIN } from "Constants";
import { ErrorMessageComponent } from "Components";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const { isLoading } = useSelector((state) => state.LoginReducer);

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [dispatch, isLoading]);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    const res = await dispatch(LoginAction(appendEmailDomain(values)));
    setErrors(res.payload.errors);
    setSubmitting(false);
    if (!res.payload.error) {
      navigate("/app/home");
      resetForm();
    }
  };

  return (
    <div className="auth-bg-img login-page">
      <div className="auth-page">
        <div className="d-flex justify-content-center align-items-end mvh-100">
          <div className="left-girl">
            <img src={Image} alt="img-1" />
          </div>
          <div className="authform">
            <div className="auth-form-box bg-signup rounded">
              <div className="box-header">
                <h2 className="text-center">{t("Login.Title")}</h2>
              </div>
              <div className="authbox-content">
                <Formik
                  initialValues={initialValues}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                  innerRef={formikRef}
                >
                  <Form>
                    <label htmlFor="email">{t("Login.Form.Email")}</label>
                    <InputGroup>
                      <Field name="email" type="text" />
                      <InputGroup.Text id="basic-addon2">
                        @{EMAIL_DOMAIN}
                      </InputGroup.Text>
                    </InputGroup>
                    <ErrorMessageComponent name="email" />

                    <label htmlFor="password">{t("Login.Form.Password")}</label>
                    <Field type="password" name="password" />
                    <ErrorMessageComponent name="password" />

                    <Button variant="primary" type="submit">
                      {t("Login.Title")}
                    </Button>
                  </Form>
                </Formik>
                <div className="text-center">
                  <Link className="forgot-link" to="/forgot-password">
                    {t("Login.ForgotPassword")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="bottom-link">
              <Link to="/register">{t("Login.RegisterLink")}</Link>
            </div>
          </div>
          <div className="right-girl">
            <img src={Image2} alt="img-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
