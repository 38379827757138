import Badge1 from "Assets/Images/new-assets/badge-1.png";
import Badge2 from "Assets/Images/new-assets/badge-2.png";
import Badge3 from "Assets/Images/new-assets/badge-3.png";

export const DIJKLANDER_ACCESS_TOKEN = "DIJKLANDER_ACCESS_TOKEN";

export const EMAIL_DOMAIN = "dijklander.nl";
// export const EMAIL_DOMAIN = "mailinator.com";

export const perPage = 15;
export const levelsPerPage = 10;
export const ranksPerPage = 5;

export const apiCallTypes = {
  get: "get",
  post: "post",
  put: "put",
  patch: "patch",
};

export const passwordRegex =
  "(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$";

export const emailRegex = /(.*)@(dijklander)\.nl/i;

export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const ranks = {
  1: <img src={Badge1} alt="badge" />,
  2: <img src={Badge2} alt="badge" />,
  3: <img src={Badge3} alt="badge" />,
};
