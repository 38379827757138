import apiCall from "Middlewares/ApiClient";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCallTypes } from "Constants";
import { saveAuthToken } from "Utils";

const initialState = {
  isLoading: false,
  user: {},
};

export const LoginAction = createAsyncThunk(
  "Login/LoginAction",
  async (payload, thunkApi) => {
    const res = await apiCall("/auth/login", apiCallTypes.post, payload, false);

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const LoginSlice = createSlice({
  name: "LoginReducer",
  initialState,
  extraReducers: {
    [LoginAction.fulfilled]: (state, { payload }) => {
      state.user = payload.data ? payload.data.user : null;
      state.isLoading = false;
      saveAuthToken(payload.data);
    },
    [LoginAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [LoginAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default LoginSlice.reducer;
