import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import LogoMain from "Assets/Images/logo-large.svg";
import "./style.scss";

const Loader = () => {
  const { t } = useTranslation();

  return (
    <div className="loader main-bg show">
      <div className="loader-content">
        <img src={LogoMain} alt="" />
        <div className="loader-bottom">
          <Spinner animation="border" variant="primary" />
          <p>{t("Common.Loading")} ...</p>
        </div>
      </div>
    </div>
  );
};

export default Loader;
