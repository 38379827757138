import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "Middlewares/ApiClient";
import { apiCallTypes } from "Constants";

const initialState = {
  isLoading: false,
  filterData: null,
  ranks: { data: [] },
};

export const LeaderboardAction = createAsyncThunk(
  "Leaderboard/LeaderboardAction",
  async (payload, thunkApi) => {
    const res = await apiCall(
      `/my/leaderboard/ranks`,
      apiCallTypes.get,
      null,
      true,
      payload
    );

    if (res.error !== true) {
      return res;
    }
    return thunkApi.rejectWithValue(res);
  }
);

export const LeaderboardSlice = createSlice({
  name: "LeaderboardReducer",
  initialState,
  extraReducers: {
    [LeaderboardAction.fulfilled]: (state, { payload }) => {
      [state.filterData, state.ranks] = [
        payload?.data?.filter_data,
        payload?.data?.rank_listing,
      ];
      state.isLoading = false;
    },
    [LeaderboardAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [LeaderboardAction.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export default LeaderboardSlice.reducer;
